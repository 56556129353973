import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "Few minutes",
      title: "Withdraw from Crypto.com CEX",
      place: "Easy",
      desc: "Swap any listed crypto for CRO on crypto.com Exchange or buy it directly with your credit card. Withdraw your CRO to your Metamask (or any EVM compatible wallet). Make sure to select 'cronos' network. If you don't have a crypto.com account, register now!",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "15 mins",
      title: "1. Send ATOM to your KEPLR wallet",
      place: "Medium",
      desc: "Send ATOM (Cosmos) to a new or existing KEPLR wallet. You can bridge funds with app.rocketx.exchange which supports all major blockchains and all major crypto currencies.",
    },
    {
      yearRange: "2 mins",
      title: "2. Swap ATOM for CRO with KEPLR",
      place: "Easy",
      desc: "Open you KEPLR wallet and select swap. Set the amount of ATOM on Cosmos hub that you want to swap and then select CRO on Cronos POS. Hit Next and confirm the transaction.",
    },
    {
      yearRange: "5 mins",
      title: "3. Use official Cronos Bridge",
      place: "Easy",
      desc: "Connect your KEPLR wallet on https://cronos.org/bridge. Select from Cronos POS to Cronos. Select CRO in assets and confirm. Set the amount of CRO you want to bridge and the address of your Cronos Metamask (or any EVM compatible wallet). Hit 'Transfer Assets' and confirm the transaction.",
    },
  ];

  const skills = [
    {
      name: "Web Design",
      percent: 65,
    },
    {
      name: "HTML/CSS",
      percent: 95,
    },
    {
      name: "JavaScript",
      percent: 80,
    },
    {
      name: "React JS",
      percent: 70,
    },
    {
      name: "Angular Js",
      percent: 60,
    },
    {
      name: "Bootstrap",
      percent: 99,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Bridge</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How to bridge to Cronos chain?
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">Method 1</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">Method 2</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
      </div>
    </section>
  );
};

export default Resume;
