import React from 'react';
import axios from 'axios';

export default class Holder extends React.Component {
  state = {
    holder: 0
  }

  componentDidMount() {
    axios.get(`https://api.gopluslabs.io/api/v1/token_security/25?contract_addresses=0x2cd9F7e727f79eD7779c4CA455f4A864D22D3c82`)
      .then(res => {
        const holder = res.data.result["0x2cd9f7e727f79ed7779c4ca455f4a864d22d3c82"].holder_count;
        this.setState({ holder });
      })
  }

  render() {
    return (
        <b>{this.state.holder}</b>
    )
  }
}