import React from "react";
import Holder from "./holder";

const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">CWM Token</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Meow Meow!
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Who am I?
            </h2>
            <p className="text-5">
            Just a cat. With mane.<br />< br/>
            Supply: <b>1,000,000,000</b> CWM<br />
              CA: 0x2cd9F7e727f79eD7779c4CA455f4A864D22D3c82
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  <Holder></Holder>
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
              Early <span className="fw-700">Holders</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
